import { Link } from "@tanstack/react-router"
import LogoAnna from '../assets/loga/R1-Anna.png'
import LogoVega from '../assets/loga/R2-Vega.png'
import LogoSilesia from '../assets/loga/0-Silesia.png'
import LogoJadwiga from '../assets/loga/0-Jadwiga.png'
import LogoZygmunt from '../assets/loga/1-Zygmunt.png'
import LogoComenius from '../assets/loga/2-Comenius.png'
import LogoWratyslaw from '../assets/loga/3-Wratyslaw.png'
import LogoBoguslaw from '../assets/loga/4-Boguslaw.png'
import LogoLubusia from '../assets/loga/5-Lubusia.png'
import LogoKosmos from '../assets/loga/6-Kosmos.png'
import LogoStetinum from '../assets/loga/7-Stetinum.png'
import LogoHubertus from '../assets/loga/8-Hubertus.png'
// import LogoWLP from '../assets/wlp.png'

const ListLink = ({to, children, className, icon, miasto}:{to:any, children:any, className?:string, icon?:any, miasto?:string}) => {
    let c = to ? <Link to={to} target="_blank" className={`hover:underline hover:text-gray-200 ${className}`}>{children}</Link>: children;
    let i = icon ? icon : <></>;
    return <div className='flex gap-4 items-center'>
        {i}
        <div className="text-x flex flex-col">
            {c}
            <span className="italic text-gray-400">{miasto}</span>
        </div>
    </div>
}

const Il = ({src, alt}:{src:any, alt:string})=>{
    return <div className="h-16 w-16"><img src={src} alt={alt} className='absolute h-16 hover:h-64 hover:w-64 hover:z-50 transition ease-in-out delay-50 duration-300 hover:bg-gray-900 hover:p-8'/></div>
}

const Loze = () => {

    return <div className="m-4 mx-8  text-gray-200 ">

        Loże znajdujące pod jurysdykcją <Link to="/wielka_loza_polska" className="text-link hover:text-linkhover hover:underline">Wielkiej Loży Polskiej</Link>:

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4  px-4 mb-4 ml-4 mt-8 flex-wrap gap-8">
            <ListLink to="" icon={<Il src={LogoZygmunt} alt="Logo Zygmunt" />} miasto="Wrocław">Loża Nr 1 Zygmunt</ListLink>
            <ListLink to="" icon={<Il src={LogoComenius} alt="Logo Comenius"/>} miasto="Leszno">Loża Nr 2 Comenius</ListLink>
            <ListLink to="" icon={<Il src={LogoWratyslaw} alt="Logo Wratysław" />} miasto="Wrocław">Loża Nr 3 Wratysław</ListLink>
            <ListLink to="" icon={<Il src={LogoBoguslaw} alt="Logo Boguslaw" />} miasto="Szczecin">Loża Nr 4 Bogusław</ListLink>
            <ListLink to="" icon={<Il src={LogoLubusia} alt="Logo Lubusia" />} miasto="Zielona Góra">Loża Nr 5 Lubusia</ListLink>
            <ListLink to="" icon={<Il src={LogoKosmos} alt="Logo Kosmos" />} miasto="Poznań">Loża Nr 6 Kosmos</ListLink>
            <ListLink to="" icon={<Il src={LogoStetinum} alt="Logo Stetinum" />} miasto="Szczecin">Loża Nr 7 Stetinum</ListLink>
            <ListLink to="" icon={<Il src={LogoHubertus} alt="Logo Hubertus" />} miasto="Wrocław">Loża Nr 8 Hubertus</ListLink>
        </div>

        <div className="bold font-montserrat  mt-8">Loże żeńskie:</div>
        <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4  px-4 ml-4 mb-4 ">
            <ListLink to="" icon={<Il src={LogoAnna} alt="Logo Anna" />} miasto="Wrocław">Loża Nr 1 Rebekah Anna</ListLink>
            <ListLink to="" icon={<Il src={LogoVega} alt="Logo Vega" />} miasto="Szczecin">Loża Nr 2 Rebekah Vega</ListLink>
        </ul>

        <div className="bold font-montserrat mt-8">Obozy:</div>
        <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4  px-4 mb-4 ml-4">
            <ListLink to="" icon={<Il src={LogoSilesia} alt="Logo Silesia" />} miasto="Obóz Lóż Męskich Polskich">Obóz Silesia</ListLink>
            <ListLink to="" icon={<Il src={LogoJadwiga} alt="Logo Jadwiga" />} miasto="Obóz Lóż Żeńskich Polskich">Obóz Jadwiga</ListLink>
        </ul>


        <div className="bold font-montserrat mt-8">Kluby:</div>
        <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4  px-4 mb-4 ml-4">
            <li>Klub Bractwo <span className="italic text-gray-400">(Lwów, Ukraina - działający w ramach Loży Nr 3 Wratysław)</span> </li>
        </ul>

    </div>
}

export default Loze