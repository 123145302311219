
import SzczecinskieBulwary from '../assets/szczecinskie_bulwary.png'

const ListLink = ({to, children, className}:{to:any, children:any, className?:string}) => {
    let c = to ? <a href={to} target="_blank" rel="noreferrer" className={`hover:underline hover:text-gray-200 ${className}`}>{children}</a>: children;
    return <li className=''>{c}</li>
}

const Wydawnictwa = () => {

    return <div className="m-4 mx-12  text-gray-200 ">

        <div className="text-center text-2xl font-montserrat font-bold italic text-gray-400">Wydawnictwa IOOF</div>
        
        <div className="flex items-center gap-4">
            <div>
                <img src={SzczecinskieBulwary} className="h-36 mix-blend-hard-light" alt="Szczecińskie Bulwary"/>
            </div>
            <div>
                <div className="bold font-bebasneue text-xl">Szczecińskie Bulwary</div>
                <ul className="list-disc px-4 mb-4 ml-4">
                    <ListLink to="/wydawnictwa/bulwary/biuletyn_3.pdf"> Biuletyn Nr 3<span className="italic text-gray-400">(31.03.2022)</span></ListLink>
                    <ListLink to="/wydawnictwa/bulwary/biuletyn_2.pdf"> Biuletyn Nr 2<span className="italic text-gray-400">(25.10.2021)</span></ListLink>
                    <ListLink to="/wydawnictwa/bulwary/biuletyn_1.pdf"> Biuletyn Nr 1<span className="italic text-gray-400">(02.04.2021)</span></ListLink>
                </ul>
            </div>
        </div>

    </div>
}

export default Wydawnictwa