import { useState } from "react";

    // const ListLink = ({to, children, className}:{to:any, children:any, className?:string}) => {
    //     let c = to ? <a href={to} target="_blank" rel="noreferrer" className={`hover:underline hover:text-gray-200 ${className}`}>{children}</a>: children;
    //     return <li className=''>{c}</li>
    // }


    const WydarzenieCard = ({title, children, img, onClick, active, disabled}:{title:string, children:any, img?:string, onClick?:Function, active?:boolean, disabled?:boolean}) => {
        return <div className={`max-w-sm rounded overflow-hidden shadow-lg bg-gray-90 border-2 border-gray-700 ${active?'border-2 border-white hover:border-2 hover:border-white':(disabled?'hover:border-2 hover:border-gray-700':'hover:border-2 hover:border-gray-400')}  ${disabled?'':' hover:cursor-pointer '} `} 
        onClick={()=>!disabled && onClick ? onClick() : {}}>
        {img && <img className="w-full h-48 object-cover object-center" src={img} alt={title}/>}
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2 text-white">{title}</div>
          <p className="text-gray-500 text-base">
            {children}
          </p>
        </div>
        {/* <div className="px-6 pt-4 pb-2">
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#photography</span>
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#travel</span>
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#winter</span>
        </div> */}
      </div>
    }

    const Fotka = ({src, alt, colspan}:{src:string, alt?:string, colspan:number})=>{
        return <><img src={src} alt={alt} className={`rounded-lg shadow-md col-span-${colspan}`}/></>
    }

    const Wydarzenie = ({active, children, fotki, onClose}:{active:boolean, children:any, fotki:string[], onClose?:Function}) => {
        
        if (!active) return <></>

        return <div className="border-2 p-4 rounded-md border-gray-500 shadow-2xl">
            <div className="grid grid-cols-2 gap-8 max-xl:grid-cols-1">
                <div>
                    {children}
                </div>
                <div className="grid grid-cols-2 gap-4 items-center max-md:grid-cols-1">
                    {fotki.map((f)=><Fotka src={f} colspan={ fotki.length === 1 ? 2 : 1} />) }
                </div>
            </div>
            <div className="text-right mt-8">
                <button className="bg-red-900 hover:bg-gray-600 px-8 py-2 rounded-full shadow-sm" onClick={()=>onClose ? onClose():{}}>Zamknij</button>
            </div>
        </div>
    }
    
    const WydarzeniaIndex = () => {

        const [aktywne, setAktywne] = useState(0)

        
        return <div className="m-4 mx-12  text-gray-200 ">
    
            <div className="text-center text-2xl font-montserrat font-bold italic text-gray-400 mb-8">Wydarzenia IOOF</div>

            <Wydarzenie 
                active={aktywne===1} 
                onClose={()=>setAktywne(0)}
                fotki={[
                    '/assets/wydarzenia/piknik_vega/4.JPG',
                ]}
            >
                PROGRAM PIKNIKU 04.06.2022<br/>
                Start *15.00*<br/>
                <br/>
                *15.00* Powitanie gości i rozpoczęcie sprzedaży losów<br/>
                *15.30* Występ ukraińskiej bandurzystki<br/>
                *16.00* Koncert zespołu jazzowego JazzDuo.Szcz<br/>
                *16.30* Występ aktora Teatru Polskiego Michała Janickiego<br/>
                *17.00* Koncert zespołu jazzowego JazzDuo Szcz<br/>
                *18.00* Konkursy<br/>
                *19.00* Loteria<br/>
                *20.00* Karaoke, tańce<br/>
                *22.00* Ognisko i pieczenie kiełbasek, muzyka, tańce do białego rana<br/>
                <br/>
                <hr className="my-4"/>
                DZIĘKUJEMY ZA DUŻE ZAINTERESOWANIE, potwierdzenia - a niezdecydowanych zapraszamy.<br/>
                Prosimy o wpłaty na konto, gdyż na pikniku nie będzie możliwości zbierania pieniążków<br/>

                <hr className="my-4"/>
                <p>

                Moi Kochani<br/>
                <br/>
                W imieniu Loży Vega chciałabym bardzo podziękować Braciom I ich gościom za zaszczycenie swoją obecnością naszego pikniku.<br/>
                Dziękuję za Wasz udział, za wsparcie, za mile słowa I cudowną atmosferę. To było dla nas olbrzymie przedsięwzięcie, ale dla tak cudownych osób i wspólnej integracji naszego ruchu to czysta przyjemność. Mam nadzieję ,że za rok spotkamy się znowu w jeszcze większym składzie.<br/>
                Jeszcze raz bardzo dziękuję i życzę dalszych dni pełnych pozytywnych wrażeń<br/>
                <br/>
                Pozdrawiam w duchu PMP<br/>
                Mirka Sawaszkiewicz<br/>
                <br/>
                Więcej zdjęć znajdziecie klikając w poniższy link<br/>
                <a href="https://cloud.ioof.pl/s/QsXwr3SG4skDQs2" className="underline italic text-link hover:text-linkhover" >https://cloud.ioof.pl/s/QsXwr3SG4skDQs2</a><br/>

                </p>
            </Wydarzenie>


            <Wydarzenie 
                active={aktywne===2} 
                onClose={()=>setAktywne(0)}
                fotki={[
                    '/assets/wydarzenia/mielno2022/1.jpg',
                ]}
            >
                Zapraszamy do obejrzenia fotorelacji z corocznego wakacyjnego integracyjnego spotkania Rebek Lóż Anna i Vega
                Zdjęcia są w chmurze
                Pozdrawiamy i do zobaczenia
            </Wydarzenie>
            
            <div className="flex gap-4 flex-wrap mt-4">
                <WydarzenieCard title="Piknik Loży Vega" img="/assets/wydarzenia/piknik_vega/4.JPG" active={aktywne===1} onClick={()=>setAktywne(1)}>
                    Data:  04.06.2022 <br/>

                    DZIĘKUJEMY ZA DUŻE ZAINTERESOWANIE, potwierdzenia - a niezdecydowanych zapraszamy.<br/>
                    Prosimy o wpłaty na konto, gdyż na pikniku nie będzie możliwości zbierania pieniążków<br/>
                </WydarzenieCard>
                
                <WydarzenieCard title="Mielno 2022" img="/assets/wydarzenia/mielno2022/1.jpg" active={aktywne===2} onClick={()=>setAktywne(2)}>
                Zapraszamy do obejrzenia fotorelacji z corocznego wakacyjnego integracyjnego spotkania Rebek Lóż Anna i Vega
                Zdjęcia są w chmurze
                Pozdrawiamy i do zobaczenia
                </WydarzenieCard>

                <WydarzenieCard title="Jubileuszowy 25 Western Piknik" active={aktywne===3} disabled={true}>
                Zapraszamy do obejrzenia fotorelacji z corocznego wakacyjnego integracyjnego spotkania Rebek Lóż Anna i Vega
                Zdjęcia są w chmurze
                Pozdrawiamy i do zobaczenia
                </WydarzenieCard>

                

            </div>
    
        </div>
    }

export default WydarzeniaIndex