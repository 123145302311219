const SlideImage = ({
  src,
  alt,
  active,
  label,
  children
}: {
  src: string;
  alt: string;
  active?: boolean;
  label: string;
  children?:any;
}) => {
  //     return <div className={`carousel-item ${active?'active':''} float-left w-full`}>
  //     <img
  //       src={src}
  //       className="block"
  //       alt={alt}
  //     />
  //   </div>

  return (
    <div className={`carousel-item ${active?'active':''} relative float-left w-full `}>
      <div className="block w-full shadow-2xl">
        <div className={`w-full h-[36rem] max-lg:h-[24rem] max-sm:h-[16rem] overflow-hidden rounded-lg`}>
        <img className=" w-full h-full object-cover object-center" src={src} alt={alt}/>
        </div>
      </div>

      <div className="carousel-caption hidden md:block absolute text-center bg-slate-900 opacity-75 rounded-md drop-shadow-md p-2 pb-6 font-montserrat border-2 border-gray-700">
        <h5 className="text-3xl font-bebasneue">{label}</h5>
        {children}
      </div>

      <div className="block md:hidden text-center text-white bg-slate-900 opacity-75 rounded-b-md drop-shadow-md p-2 pb-12 font-montserrat border-b-2  border-gray-700">
        <h5 className="text-3xl font-bebasneue">{label}</h5>
        {children}
      </div>

    </div>
  );
};

const PMPCarusel = () => {
  return (
    <div
      id="carouselExampleCaptions"
      className="carousel slide relative drop-shadow-md"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>
      <div className="carousel-inner relative w-full overflow-hidden">
        <SlideImage
          src="/assets/AdobeStock_363563655.jpeg"
          alt="Przyjaźń"
          active={true}
          label="Przyjaźń"
        >Dzięki przynależności do Odd Fellow zyskujesz nowych przyjaciół wśród członków swojej Loży oraz szerokie grono Braci i Sióstr na całym świecie.  Jesteśmy różnorodną organizacją i posiadamy członków wszystkich wyznań, ras, płci, zawodów i umiejętności, z ponad 600 000 członkami w ponad 10 000 Lóż w 30 krajach.</SlideImage>
        <SlideImage
          src="/assets/AdobeStock_309576547.jpeg"
          alt="Miłość"
          label="Miłość"
          >
            <p>Historycznym przykazem Odd Fellows jest "odwiedzanie chorych, pomaganie potrzebującym, grzebanie zmarłych i wychowywanie sierot".</p>
            <p>
          Masz możliwość aktywnego udziału w pomaganiu swojej społeczności, a światu stać się lepszym miejscem. Miejscowe Loże pomagają swoim społecznościom i są jednocześnie zjednoczone w większych projektach.</p>
          </SlideImage>
        <SlideImage
          src="/assets/AdobeStock_512514069.jpeg"
          alt="Prawda"
          label="Prawda"
          >
            Bądź częścią HISTORII i TAJEMNICY. Jako Odd Fellow możesz być członkiem jednego z najstarszych i największych zakonów braterskich na świecie. Rytuały, symbole i stopnie są zaprojektowane, aby "podnosić i poprawiać charakter ludzki". Dzięki przyjmowaniu tych lekcji do serca stajesz się lepszą osobą i w konsekwencji pomagasz uczynić świat wokół ciebie lepszym miejscem.
          </SlideImage>


      </div>
      <button
        className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span
          className="carousel-control-prev-icon inline-block bg-no-repeat"
          aria-hidden="true"
        ></span>
        <span className="visually-hidden">Poprzedni</span>
      </button>
      <button
        className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span
          className="carousel-control-next-icon inline-block bg-no-repeat"
          aria-hidden="true"
        ></span>
        <span className="visually-hidden">Następny</span>
      </button>
    </div>
  );
};

export default PMPCarusel;
