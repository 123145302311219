
import { MdOutlineForum } from 'react-icons/md'
import {AiTwotoneCloud, AiFillVideoCamera} from 'react-icons/ai'
import {IoMdMail} from 'react-icons/io'
import {BsCalendarDate} from 'react-icons/bs'
import LogoAnna from '../assets/loga/R1-Anna.png'
import LogoVega from '../assets/loga/R2-Vega.png'
import LogoSilesia from '../assets/loga/0-Silesia.png'
import LogoJadwiga from '../assets/loga/0-Jadwiga.png'
import LogoZygmunt from '../assets/loga/1-Zygmunt.png'
import LogoComenius from '../assets/loga/2-Comenius.png'
import LogoWratyslaw from '../assets/loga/3-Wratyslaw.png'
import LogoBoguslaw from '../assets/loga/4-Boguslaw.png'
import LogoLubusia from '../assets/loga/5-Lubusia.png'
import LogoKosmos from '../assets/loga/6-Kosmos.png'
import LogoStetinum from '../assets/loga/7-Stetinum.png'
import LogoHubertus from '../assets/loga/8-Hubertus.png'
import LogoWLP from '../assets/wlp.png'

const Aa = ({href, children, icon}:{href:string, children:any, icon?:any})=>{
    return <a href={href}   target={"_blank"} rel="noreferrer" 
    
    className='transition ease-in-out delay-50 duration-300 px-8 py-4 rounded-full text-3xl font-semibold  hover:bg-link bg-red-800 text-red-100 hover:text-black flex items-center gap-2 shadow-lg '>
        {icon?icon:''}
        {children}</a>
}

const Aaa = ({href, children, icon}:{href:string, children:any, icon?:any})=>{
    return <a href={href}   target={"_blank"} rel="noreferrer" 
    
    className='transition ease-in-out delay-50 duration-300 px-8 py-4 rounded-full text-xl font-semibold hover:bg-link bg-red-800 text-red-100 hover:text-black flex items-center gap-2 shadow-lg '>
        {icon?icon:''}
        {children}</a>
}

const Intranet = () => {

    return <div className="m-4 mx-8  text-gray-200">
        
        <div className="flex gap-4 justify-center my-16 flex-wrap">
            <Aa href={"https://forum.ioof.pl"} icon={<MdOutlineForum/>}>Forum</Aa>
            <Aa href={"https://cloud.ioof.pl"} icon={<AiTwotoneCloud/>}>Chmura</Aa>
            <Aa href={"https://meet.ioof.pl"} icon={<AiFillVideoCamera/>}>Wideo</Aa>
            <Aa href={"https://poczta.ioof.pl"} icon={<IoMdMail/>} >Poczta</Aa>
            <Aa href={"https://cloud.ioof.pl/apps/calendar/p/jwdMS6LJ2y3M63pA/dayGridMonth/now"} icon={<BsCalendarDate/>} >Kalendarz</Aa>
        </div>
       
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 my-16 ">
        <Aaa href={"https://meet.ioof.pl/ANNA"} icon={<img src={LogoAnna} alt="Logo Anna" className='h-16'/>}>Wideo Anna</Aaa>
        <Aaa href={"https://meet.ioof.pl/BOGUSLAW"} icon={<img src={LogoBoguslaw} alt="Logo Boguslaw" className='h-16'/>}>Wideo Bogusław</Aaa>
        <Aaa href={"https://meet.ioof.pl/COMENIUS"} icon={<img src={LogoComenius} alt="Logo Comenius" className='h-16'/>}>Wideo Comenius</Aaa>
        <Aaa href={"https://meet.ioof.pl/HUBERTUS"} icon={<img src={LogoHubertus} alt="Logo Hubertus" className='h-16'/>}>Wideo Hubertus</Aaa>
        <Aaa href={"https://meet.ioof.pl/JADWIGA"} icon={<img src={LogoJadwiga} alt="Logo Jadwiga" className='h-16'/>}>Wideo Jadwiga</Aaa>
        <Aaa href={"https://meet.ioof.pl/KOSMOS"} icon={<img src={LogoKosmos} alt="Logo Kosmos" className='h-16'/>}>Wideo Kosmos</Aaa>
        <Aaa href={"https://meet.ioof.pl/LUBUSIA"} icon={<img src={LogoLubusia} alt="Logo Lubusia" className='h-16'/>}>Wideo Lubusia</Aaa>
        <Aaa href={"https://meet.ioof.pl/SILESIA"} icon={<img src={LogoSilesia} alt="Logo Silesia" className='h-16'/>}>Wideo Silesia</Aaa>
        <Aaa href={"https://meet.ioof.pl/STETINUM"} icon={<img src={LogoStetinum} alt="Logo Stetinum" className='h-16'/>}>Wideo Stetinum</Aaa>
        <Aaa href={"https://meet.ioof.pl/VEGA"} icon={<img src={LogoVega} alt="Logo Vega" className='h-16'/>}>Wideo Vega</Aaa>
        <Aaa href={"https://meet.ioof.pl/WRATYSLAW"} icon={<img src={LogoWratyslaw} alt="Logo Wratysław" className='h-16'/>}>Wideo Wratysław</Aaa>
        <Aaa href={"https://meet.ioof.pl/ZYGMUNT"} icon={<img src={LogoZygmunt} alt="Logo Zygmunt" className='h-16'/>}>Wideo Zygmunt</Aaa>
        <Aaa href={"https://meet.ioof.pl/WLP"} icon={<img src={LogoWLP} alt="Logo WLP" className='h-16'/>}>Wideo WLP</Aaa>

        <Aaa href={"https://meet.ioof.pl/SZKOLENIE"} icon={<AiFillVideoCamera className='h-12 w-12'/>}>Wideo Szkolenie</Aaa>
        <Aaa href={"https://meet.ioof.pl/UKRAINA"} icon={<AiFillVideoCamera className='h-12 w-12'/>}>Wideo Ukraina</Aaa>

        </div>
       
    </div>
}

export default Intranet