
import IOOFLogo from '../assets/ioof.png'
import { Link } from "@tanstack/react-router";

const TopLine = () => {

    return <div className="bg-gray-900 text-gray-200 font-bebasneue p-2 px-8 text-2xl flex">
     
     <div className='grow items-center mr-2'><Link to="/">Niezależny Zakon Odd Fellows <span className='md:hidden'><br/></span> w Rzeczypospolitej Polskiej</Link></div>
     
     <div className='items-center lg:hidden flex'><Link to="/intranet"><img src={IOOFLogo} alt="IOOF Logo" className='h-4 my-auto block'/></Link></div>


    </div>
}   

export default TopLine;