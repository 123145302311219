import React from "react"



const ListLink = ({to, children}:{to:any, children:any}) => {
    return <li className=''><a className="text-link hover:underline hover:text-linkhover" target={"_blank"} rel="noreferrer" href={to}>{children}</a></li>
}
    
const IoofNaSwiecie = () => {

    return <div className="m-4 mx-8 text-text">

        Odd Fellows działa na całym świecie. Oto wybrane instytucje IOOF:

        <ul className="list-disc px-4 ml-4">
            <ListLink to="https://odd-fellows.org/">Suwerenna Wielka Loża</ListLink>
            <ListLink to="https://www.oddfellows.eu/">Wielka Loża Europejska</ListLink>
            <ListLink to="https://oddfellow.se/">Szwecja</ListLink>
            <ListLink to="https://www.oddfellow.dk/">Dania</ListLink>
            <ListLink to="https://www.oddfellow.de/">Niemcy</ListLink>
            <ListLink to="https://www.oddfellow.no/">Norwegia</ListLink>
            <ListLink to="https://www.oddfellow.ch/">Szwajcaria</ListLink>
            <ListLink to="https://www.oddfellow.ee/">Estonia</ListLink>
            <ListLink to="https://www.oddfellow.cz/">Czechy</ListLink>
            <ListLink to="https://www.oddfellow.fi/">Finlandia</ListLink>
            <ListLink to="https://www.oddfellow.nl/">Holandia</ListLink>
        </ul>

    </div>

}

export default IoofNaSwiecie