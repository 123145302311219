import { Link } from "@tanstack/react-router";
import { useState } from "react";
import IOOFLogo from "../assets/ioof.png";
import "./top_menu.css";

const MenuLink = ({ to, children }: { to: any; children: any }) => {
  return (
    <li>
      <Link
        to={to}
        className="hover:underline hover:text-white text-montserrat"
        activeProps={{ className: "text-activelink hover:text-activelink" }}
      >
        {children}
      </Link>
    </li>
  );
};

const MenuItems = () => {
  return (
    <>
      <MenuLink to="/">IOOF</MenuLink>
      <MenuLink to="/historia">Historia</MenuLink>
      <MenuLink to="/wielka_loza_polska">Wielka Loża Polska</MenuLink>
      <MenuLink to="/loze">Loże i Obozy</MenuLink>
      {/* <MenuLink to="/charity">Działalność charytatywna</MenuLink> */}
      {/* <MenuLink to="/wsparcie_dla_ukrainy">Wsparcie dla Ukrainy</MenuLink> */}
      <MenuLink to="/world">Instytucje IOOF na Świecie</MenuLink>
      <MenuLink to="/wydawnictwa">Wydawnictwa</MenuLink>
      <MenuLink to="/wydarzenia">Wydarzenia</MenuLink>
      <MenuLink to="/media">Odd Fellows w Mediach</MenuLink>
      <MenuLink to="/intranet">Serwery</MenuLink>
    </>
  );
};

const TopMenu = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <div className=" flex bg-gray-800  text-gray-200 flex-wrap  gap-6 border-y-2 border-y-slate-700 shadow-xl px-8">
      <div className="flex items-center max-lg:hidden flex-none">
        <Link to="/intranet"><img src={IOOFLogo} alt="IOOF Logo" className="h-10" /></Link>
      </div>
      <nav className="flex-1 ">
        <section className="MOBILE-MENU flex flex-wrap lg:hidden my-2">
          <div
            className="HAMBURGER-ICON flex"
            onClick={() => setIsNavOpen((prev) => !prev)} // toggle isNavOpen state on click
          >
            <div className="space-y-2 hover:cursor-pointer">
              <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
              <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
              <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
            </div>
            <div
              className={`ml-4 hover:underline hover:cursor-pointer text-gray-500  ${
                isNavOpen ? "hidden" : ""
              }`}
            >
              Menu
            </div>
          </div>

          <div className={isNavOpen ? "flex-grow w-full pl-10" : "hidden"}>
            <div
              className="CROSS-ICON absolute right-0 px-8 -my-6"
              onClick={() => setIsNavOpen(false)} // change isNavOpen state to false to close the menu
            >
              <svg
                className="h-8 w-8 text-gray-600"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
            <ul className="MENU-LINK-MOBILE-OPEN flex flex-col items-start justify-between mt-4">
              <MenuItems />
            </ul>
          </div>
        </section>
        <div className="DESKTOP-MENU hidden p-4 px-8 lg:flex bg-gray-800  text-gray-200 ">
          <ul className="font-montserrat flex items-center flex-wrap gap-6 grow ">
            <MenuItems />
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default TopMenu;
