import React from 'react';

import {
  Outlet,
  RouterProvider,
  createReactRouter,
  createRouteConfig,
} from '@tanstack/react-router'

import PageFooter from './components/page_footer';
import TopLine from './components/top_line';
import TopMenu from './components/top_menu';
import Index from './pages';
import WielkaLozaPolska from './pages/wielka_loza_polska';
import Loze from './pages/loze';
import Charity from './pages/charity';
import WsparcieUkrainy from './pages/wsparcie_ukrainy';
import IoofNaSwiecie from './pages/ioof_na_swiecie';
import Historia from './pages/historia';
import CeleStatutowe from './pages/cele_statutowe';
import Wydawnictwa from './pages/wydawnictwa';
import WydarzeniaIndex from './pages/wydarzenia';
import Media from './pages/media';
import Intranet from './pages/intranet';


const rootRoute = createRouteConfig({
  component: () => (
    <div className='bg-gradient-to-r from-gray-800 via-slate-800 to-gray-900 min-h-screen min-w-full'>
      <TopLine/>
      <TopMenu/>
      <Outlet />
      <PageFooter/>
    </div>
  )
})

const indexRoute = rootRoute.createRoute({
  path: '/',
  component: Index,
})

const wlpRoute = rootRoute.createRoute({
  path: '/wielka_loza_polska',
  component: WielkaLozaPolska,
})

const lozeRoute = rootRoute.createRoute({
  path: '/loze',
  component: Loze,
})

const charityRoute = rootRoute.createRoute({
  path: '/charity',
  component: Charity,
})

const wsparcieUkrainyRoute = rootRoute.createRoute({
  path: '/wsparcie_ukrainy',
  component: WsparcieUkrainy,
})

const worldRoute = rootRoute.createRoute({
  path: '/world',
  component: IoofNaSwiecie,
})

const historiaRoute = rootRoute.createRoute({
  path: '/historia',
  component: Historia,
})

const celeStatutoweRoute = rootRoute.createRoute({
  path: '/cele_statutowe',
  component: CeleStatutowe,
})

const wydawnictwaRoute = rootRoute.createRoute({
  path: '/wydawnictwa',
  component: Wydawnictwa,
})

const wydarzeniaRoute = rootRoute.createRoute({
  path: '/wydarzenia',
  component: WydarzeniaIndex,
})

const mediaRoute = rootRoute.createRoute({
  path: '/media',
  component: Media,
})

const intranetRoute = rootRoute.createRoute({
  path: '/intranet',
  component: Intranet,
})

///wydarzeniaRoute.addChildren(...App.)

const routeConfig = rootRoute.addChildren([indexRoute, wlpRoute, lozeRoute, charityRoute, wsparcieUkrainyRoute, worldRoute, historiaRoute, celeStatutoweRoute, wydawnictwaRoute, wydarzeniaRoute, mediaRoute, intranetRoute])
const router = createReactRouter({ routeConfig })

function App() {
  return <RouterProvider router={router} />
}

export default App
