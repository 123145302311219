import { Link } from '@tanstack/react-router'

const PageFooter = () => {

    return     <footer className="bg-gray-800 text-gray-500 py-4 font-montserrat my-4 text-xs border-y-2 border-y-gray-700 shadow-md shadow-slate-900">
    <div className="container">

      <div className="grid grid-cols-6 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-4 mx-8 mt-2 font-montserrat italic">
        <div className="col-span-2">
          <div className='font-semibold'>Stowarzyszenie Bratniej Pomocy Odd Fellows w Rzeczypospolitej Polskiej</div>
          <div>ul. Kołłątaja 32/6</div>
          <div>50-005 Wrocław</div>
          <div>Polska</div>
          <div>Email: <a href="mailto:sekretariat@ioof.pl" className='underline hover:text-white'>sekretariat@ioof.pl</a></div>
          
        </div>
        <div>
          <div>NIP: 8971528446</div>
          <div>REGON: 930068647</div>
          <div>KRS: 0000065259</div>
        </div>

        <div>
          <ul>
            <li><a href="http://www.ioof.org/Jurisdictions" target={"_blank"} rel="noreferrer" className='underline hover:text-white'>Jurysdykcje IOOF na świecie</a></li>
            <li><a href="https://www.oddfellows.eu/"  target={"_blank"} rel="noreferrer" className='underline hover:text-white'>Grand Lodge of Europe</a></li>
          </ul>
        </div>

        <div>
          <ul>
            <li><Link to={"/cele_statutowe"} className='underline hover:text-white'>Cele statutowe Stowarzyszenia</Link></li>
          </ul>
        </div>

      </div>
    </div>

    <p className="text-left mx-8 my-4 text-small italic">Copyright &copy; {new Date().getFullYear()} SBP Odd Fellows w Rzeczypospolitej Polskiej</p>
  </footer>

}   

export default PageFooter