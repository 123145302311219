import WLPLogo from '../assets/wlp.png'
import WLPgrupowe from '../assets/wlp_grupowe.jpeg'

const Urzednik = ({nazwisko, tytul, foto, position}:{nazwisko:string, tytul:string, foto?:string, position?:string}) => {
    position = position || "object-center"
    return <div className="bg-gray-800 p-0 shadow rounded-md border-2 border-gray-900">
        <div className="bg-gradient-to-r from-slate-900 via-gray-900 to-zinc-900 w-full h-64 rounded-t-md p-0 overflow-clip">
            {foto && <img src={foto } alt="zdjęcie" className={`w-full h-64 object-cover ${position}`} />}
            {!foto && <img src={"/assets/wlp.png"} alt="zdjęcie" className="w-full h-64 object-contain object-center" />}
        </div>
        <div className="p-4">
            <div className="italic font-semibold">{nazwisko}</div>
            <div className="">{tytul}</div> 
        </div>
    </div>
}
const WielkaLozaPolska = () => {

    return <div className='grid grid-cols-2 max-xl:grid-cols-1 mx-8'>
        
        <div className='flex'>
            <div className='mx-auto mt-16'><img src={WLPgrupowe} alt="Urzędnicy WLP" className='shadow rounded-sm'/>
            <div className='mt-2 text-text font-montserrat italic text-sm'>Urzędnicy WLP trzeciej kadencji na lata 2022-2026 
             z Wielkim Mistrzem Wielkiej Loży Europejskiej Mortenem Buanem</div>
            </div>
        </div>
        
        <div className="m-4 mx-8  text-gray-200 max-w-3xl font-montserrat">

        <div className="font-bold text-2xl font-bebasneue">Urzędnicy Wielkiej Loży Polskiej</div>

        <div className="">Urzędnicy Wybierani:</div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
            <Urzednik nazwisko="Jacek Adamczak" tytul="Wielki Mistrz WLP" foto='/assets/urzednicy/Jacek_Adamczak.jpeg' position='object-top'/>
            <Urzednik nazwisko="Mirosława Sawaszkiewicz" tytul="Zastępca Wielkiego Mistrza WLP"/>
            <Urzednik nazwisko="Janusz Szczepański" tytul="Zastępca Wielkiego Mistrza WLP"/>
            <Urzednik nazwisko="Andrzej Sekuła" tytul="Wielki Sekretarz WLP"/>
            <Urzednik nazwisko="Roman Dera" tytul="Wielki Skarbnik WLP"/>
            <Urzednik nazwisko="Janusz Jerzak" tytul="Wielki Kwestor"/>
        </div>

        Urzędnicy Mianowani:
        <div className="grid grid-cols-3 gap-4 p-4">
            <Urzednik nazwisko="Henryk Patyk" tytul="Wielki Kapłan WLP"/>
            <Urzednik nazwisko="Wacław Wieczorek" tytul="Wielki Marszałek WLP"/>
            <Urzednik nazwisko="Andrzej Krucewicz" tytul="Wielki Herold WLP"/>
            <Urzednik nazwisko="Ryszard Panasiuk" tytul="Wielki Strażnik WLP"/>
            <Urzednik nazwisko="Włodzimierz Jarmundowicz" tytul="Wielki Lekarz WLP"/>
            <Urzednik nazwisko="Andrzej Tomkowiak" tytul="Zastępca Wielkiego Sekretarza"/>
            <Urzednik nazwisko="Andrzej Hachoł" tytul="Zastępca Wielkiego Skarbnika"/>
            <Urzednik nazwisko="Zbigniew Gumienny" tytul="Zastępca Wielkiego Marszałka"/>
        </div>
    </div>
</div>
}

export default WielkaLozaPolska;