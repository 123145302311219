
const ListLink = ({to, medium, data, children, className}:{to:any, medium:string, data:string, children:any, className?:string}) => {
    
    
    return <div className='flex flex-col gap-2 my-8 font-montserrat' >
        <div className="flex gap-2">
            <div className='w-28 italic'>{data}</div>
            <div className="flex-grow font-bold">{medium}</div>
        </div>
        <div className='flex-grow'><a href={to} target="_blank" rel="noreferrer" className={`hover:underline text-link hover:text-linkhover ${className}`}>{children}</a></div>
    </div>
}



const Media = () => {

    return <div className="m-4 mx-12  text-gray-200 ">

        <div className="text-center text-2xl font-montserrat font-bold italic text-gray-400">IOOF w Mediach</div>
        
        <div className="my-8">
            <div>
                <ListLink medium="Kanał 7 - telewizja ukraińska" to={"https://7kanal.com.ua/2022/12/26/v-odesi-volontery-vstanovyly-shche-visim-punktiv-nezlamnosti-orhanizuvaty-ikh-dopomohly-poliaky/"} 
                    data={'2022-12-26'}>Nasze punkty naładowania telefonów i dostępu do internetu w Odessie</ListLink>

                <ListLink medium="Kanał 4TV - telewizja ukraińska" to={"https://tv4.te.ua/%d0%bf%d0%b5%d1%80%d1%88%d0%b8%d0%b9-%d0%bf%d1%83%d0%bd%d0%ba%d1%82-%d0%be%d0%b1%d1%96%d0%b3%d1%80%d1%96%d0%b2%d0%b0%d0%bd%d0%bd%d1%8f-%d0%b7%d0%b0-%d0%bf%d1%96%d0%b4%d1%82%d1%80%d0%b8%d0%bc%d0%ba/"} 
                    data={'2022-12-23'}>Informacja o naszej działalności w ukraińskiej telewizji</ListLink>

                <ListLink medium="Robert Makłowicz Polska" to={"https://youtu.be/ip3kb4gVxRY?t=174"} 
                    data={'2022-12-23'}>Wzmianka o Odd Fellows w Gdańsku</ListLink>



                {/* <ListLink medium="Gazeta Wrocławska" to={"https://gazetawroclawska.pl/budynek-dawnego-kina-lwow-we-wroclawiu-znow-wystawiony-na-sprzedaz-mial-tam-powstac-klub-fitness-zdjecia/ar/c9-16202285"} 
                    data={'2022-04-01'}>Wzmianka o dawnej siedzibie Odd Fellows przy ul. Hallera we Wrocławiu</ListLink> */}

                <ListLink medium="Wrocław Nasze Miasto" to={"https://wroclaw.naszemiasto.pl/kamienice-z-wegielnica-przed-wojna-we-wroclawiu-dzialalo/ar/c13-203379"} 
                    data={'2022-02-12'}>Artykuł o przedwojennej działalności Odd Fellows we Wrocławiu</ListLink>

                <ListLink medium="Gazeta Uczelniana Akademii Medycznej" to={"https://www.umed.wroc.pl/gazeta_uczelniana/r14/139.pdf"} 
                    data={'2022 styczeń'}>Akcja charytatywna „Pomóż wstać!”</ListLink>

                <ListLink medium="Wroclife.pl" to={"https://archiwum.wroclife.pl/nasze-miasto/wroclawskie-archistorie/puk-puk-czy-tu-mieszka-bauhaus-kino-lwow/"} 
                    data={'2020-12-26'}>Wzmianka o Odd Fellows oraz starej siedzibie przy ul. Hallera we Wrocławiu</ListLink>

                {/* <ListLink medium="Echo Średzkie" to={"http://www.echosredzkie.pl/wiadomosc/12726,masonami-nie-jestesmy-ale-tajemnice-mamy"} 
                    data={'2012-12-18'}>Masonami nie jesteśmy, ale tajemnice mamy</ListLink> */}

                {/* <ListLink medium="Gazeta Wrocławska" to={"https://gazetawroclawska.pl/pomaganie-ma-sens/ar/113157"} 
                    data={'2009-05-12'}>Wywiad z Januszem Jurgielańcem</ListLink> */}

            </div>
        </div>

    </div>
}

export default Media