import Kontener from "../components/kontener";

const CeleStatutowe = () => {
    return <div className="mx-8 my-4">
    
        <Kontener title={"Cele Statutowe Stowarzyszenia Bratniej Pomocy Odd Fellows w Rzeczypospolitej Polskiej"}>
        Wyciąg ze statutu Stowarzyszenia:

        <ol className="list-roman list-inside my-4 font-bold text-xl font-montserrat" start={2}>
            <li> Zasady i cele
                <div className="font-normal text-sm">
            <div className="text-3xl font-bold text-center mt-8 mb-4">&sect;5</div>

                        <ol className="ml-4 list-decimal ml-12 ">
                            <li> Celem Stowarzyszenia jest:
                                <ol className="ml-12 list-alpha"> 
                                    <li>realizowanie i inspirowanie działań charytatywnych na rzecz osób starszych, ubogich i sierot oraz wszystkich innych znajdujących się w potrzebie,</li>
                                    <li>uczestniczenie w realizacji programów i inicjatyw na rzecz ochrony zdrowia i opieki społecznej, oraz pomocy rodzinom i osobom w trudnej sytuacji życiowej,</li>
                                    <li>prowadzenie działalności wspomagającej rozwój gospodarczy, w tym rozwój przedsiębiorczości,</li>
                                    <li>krzewienie wzorców i wartości etycznych, opartych na przyjaźni, miłości i prawdzie,</li>
                                    <li>tworzenie organizacyjnych i materialnych podstaw do kształtowania wśród swych członków i w otoczeniu Stowarzyszenia powyższych wzorców i wartości etycznych, zakorzenionych w kulturze europejskiej.</li>
                                </ol>
                            </li>
                            <li>Stowarzyszenie nie zajmuje się działalnością polityczną i religijną.</li>
                            <li>Z zastrzeżeniem postanowień ust. 2, Stowarzyszenie może współdziałać z władzami samorządowymi i administracją państwową oraz z innymi instytucjami w sprawach dotyczących realizacji celów Stowarzyszenia.</li>
                            <li>Stowarzyszenie prowadzi zarówno odpłatną, jak i nieodpłatną działalność pożytku publicznego w zakresie realizacji celów statutowych.</li>
                            <li>Stowarzyszenie może inicjować lub organizować, po uprzednim spełnieniu warunków wymaganych przepisami prawa, przedsięwzięcia gospodarcze, w tym prowadzenie własnej działalności gospodarczej, której dochody przeznaczone będą wyłącznie na działalność statutową Stowarzyszenia.</li>

                        </ol>
 
                        <div className="text-3xl font-bold text-center mt-8 mb-4">&sect;6</div>
                        Stowarzyszenie realizuje swoje cele głównie poprzez:
                        <ol className="ml-12 list-alpha">
                            <li>gromadzenie i wykorzystanie na cele charytatywne i pomocy społecznej środków materialnych i finansowych otrzymywanych w kraju i z zagranicy,</li>
                            <li>propagowanie idei efektywności wspólnych działań na rzecz osób znajdujących się w trudnej sytuacji życiowej,</li>
                            <li>wspieranie organizacyjne i finansowe ludzi znajdujących się z różnych przyczyn w potrzebie, a także roztaczanie opieki nad chorymi i sierotami,</li>
                            <li>rozwijanie różnorodnych form współpracy i wymiany doświadczeń z podmiotami krajowymi, zagranicznymi i międzynarodowymi o zbieżnym profilu działalności,</li>
                            <li>inicjowanie, popieranie i prowadzenie poradnictwa na rzecz osób znajdujących się w trudnej sytuacji życiowej,</li>
                            <li>organizowanie pomocy rzeczowej i finansowej dla placówek oświatowych, ochrony zdrowia i opieki społecznej,</li>
                            <li>organizowanie spotkań, konferencji i sympozjów poświęconych nawiązywaniu kontaktów i współpracy gospodarczej zarówno pomiędzy podmiotami krajowymi jak i zagranicznymi,</li>
                            <li>organizowanie zgodnie z obowiązującym prawem publicznych zbiórek środków pieniężnych i rzeczowych w zakładach pracy, instytucjach oraz przy okazji imprez kulturalnych, sportowych i dobroczynnych,</li>
                            <li>uświadamianie członkom Stowarzyszenia ich obowiązków etycznych wobec siebie i społeczeństwa, między innymi poprzez dyskusje i prelekcje w siedzibie Stowarzyszenia oraz ceremoniał organizacyjny.</li>
                        </ol>
                </div>

            </li>
        </ol>

        </Kontener> 

    </div>
}


export default CeleStatutowe;