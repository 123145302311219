import Kontener from "../components/kontener";
import PMPCarusel from "../components/pmp_carusel";




const Index = () => {

    

  return (
    <>
    <div className="mx-1 my-1"><PMPCarusel/></div>
    
    <div className="m-4 mx-8 text-text">
      
      <Kontener title="Ciekawostka">
        Odd Fellows (lub Oddfellows) to różnego rodzaju organizacje, które zostały
        założone w XVIII i XIX wieku jako towarzystwa humanitarne i filantropijne.
        Nazwa pochodzi od faktu, że członkowie należeli do "nietypowych"
        zawodów lub byli "innymi" w jakiś sposób. Odd Fellows działają w różnych
        krajach na całym świecie i są znani z ich charytatywności i wsparcia dla
        potrzebujących. W niektórych krajach istnieją również organizacje związane
        z Odd Fellows, takie jak Rebekah (dla kobiet) i Juvenile Odd Fellows (dla
        dzieci).
        Wszystkie Loże Odd Fellows zawsze szanują lokalne prawo.
      </Kontener>


    <Kontener title="Nasza misja">
        <p className="mb-2">Jako organizacja, Independent Order of Odd Fellows (IOOF) ma na celu stworzenie ram, które promują osobisty i społeczny rozwój. Stopnie Loży i działania mają na celu poprawę i podniesienie każdej osoby do wyższego, szlachetniejszego poziomu; rozszerzenie współczucia i pomocy dla tych, którzy jej potrzebują, ułatwienie ich ciężarów i uwolnienie od ciemności rozpaczy; walkę z wszelkimi formami występku i bycie wielką moralną siłą i wpływem na dobro ludzkości.</p>
        <p className="mb-2">Dla członków stopnie w Odd Fellowship nacisk kładą na opuszczenie starego życia i rozpoczęcie lepszego, na przyjmowanie podróżnych i pomaganie potrzebującym.</p>
        <p className="mb-2">Rozkaz IOOF brzmi: "odwiedzaj chorych, pomagaj potrzebującym, pogrzebaj zmarłych i wychowuj sieroty". Konkretnie, dzisiejszy IOOF poświęcony jest następującym celom:</p>
        <ul className="list-disc ml-8 mb-2">
            <li>Poprawa i podniesienie charakteru ludzkości poprzez promowanie zasad przyjaźni, miłości, prawdy, wiary, nadziei, miłosierdzia i powszechnej sprawiedliwości.</li>
            <li>Pomaganie w uczynieniu świata lepszym miejscem do życia poprzez wzajemne pomaganie sobie, społeczności, potrzebującym, młodzieży, osobom starszym, środowisku i społeczności we wszelkich możliwych sposobach.</li>
            <li>Promowanie dobrej woli i zgody między ludźmi i narodami poprzez zasadę powszechnego braterstwa, przyjmując przekonanie, że wszyscy ludzie, niezależnie od rasy, narodowości, religii, statusu społecznego, płci, stopnia i stanowiska, są braćmi i siostrami.</li>
        </ul>
        <p className="mb-2">Loża Odd Fellow jest miejscem wszelkiego rodzaju wspólnoty, od spędzania czasu i dobrej zabawy, poprzez dyskusje, nawiązywanie kontaktów, współpracę nad twórczymi i budującymi projektami, zawsze z myślą nie tylko o własnym zadowoleniu, ale także o tym, co możemy zaoferować szerokiej społeczności. Idealna Loża to świetne miejsce na dużo pozytywnej energii i wszyscy członkowie są zachęcani do przyczynienia się swoją cegiełką.</p>
        <p className="mb-2">Ponadto Loże tworzą międzynarodowe sieci społeczne braci i sióstr, które rozciągają się na 30 krajów na całym świecie. Jeśli podróżowanie jest twoim zainteresowaniem, przynależność może zapewnić cenne kontakty, które bardzo chętnie powitają międzynarodowego gościa i pomogą w jego przedsięwzięciach oraz podróżach, jeśli to możliwe.</p>
        <p className="mb-2">Niezależny Zakon Odd Fellows (I.O.O.F.) nie dyskryminuje żadnej osoby ze względu na niepełnosprawność, wiek (poza minimalnym wymogiem do przystąpienia do zakonu (loży, klubu lub grupy), etniczność, płeć, rasę, orientację seksualną, religię lub inne tożsamości społeczne. </p>
        <p className="mb-2"></p>
    </Kontener>

    <Kontener title="Dla Ciebie">
        Jako członek, który pracuje swoją drogą przez różne stanowiska w Loży, nauczysz się:
        <ul className="list-disc ml-8 mb-2">
            <li>Odpowiedzialność wobec wszystkich braci i sióstr Loży. Przestrzeganie wartości IOOF i uczynienie uczestniczenia i pomocy na funkcjach Loży osobistym celem.</li>
            <li>Uczestniczenie w rytuałach Loży i nauka różnych ról i urzędów.</li>
            <li>Mówienie przed grupą lub czytanie głośno przed grupą.</li>
            <li>Robić poprawne protokoły dotyczące uczestnictwa w oficjalnym posiedzeniu biznesowym z użyciem <a className="text-white hover:text-linkhover hover:underline" href="https://en.wikipedia.org/wiki/Robert%27s_Rules_of_Order" rel="noreferrer" target={"_blank"} >zasad Roberta</a> (procedury parlamentarne).</li>
            <li>Jak pracować z innymi i skutecznie realizować różne wydarzenia i zbiórki pieniędzy związane z Lożą.</li>
            <li>Jak rozmawiać z szacunkiem przy braku zgody.</li>
            <li>Pomijać różnice i pracować razem na rzecz dobra Loży, nawet jeśli to nie przynosi ci bezpośrednich korzyści.</li>
            <li>Odpowiedni sposób prowadzenia protokołu ze spotkania i utrzymania ważnych dokumentów w porządku, właściwie wypełnionych i sprawnie zarchiwizowanych.</li>
            <li>Jak prowadzić poprawne księgi finansowe, opracowywać i utrzymywać budżet dla Loży.</li>
            <li>Umiejętności marketingowych w celu promowania pracy Loży za pomocą mediów społecznościowych i tradycyjnych, takich jak gazety, radio i telewizja.</li>
            <li>Umiejętności liderskich, by być silnym i pewnym siebie.</li>
            <li>Jak prowadzić spotkania i utrzymywać kontrolę oraz porządek na Loży oraz jak skutecznie mediować w przypadku niezgodności.</li>
            <li>Być zarządcą odpowiedzialnym za opiekę nad własnością loży,  jej lokatorami oraz zarządzaniem historycznymi dokumentami lub artefaktami.</li>
            <li>Jak być skutecznym mentorem dla innych, którzy chcą się od ciebie uczyć.</li>
        </ul>
    </Kontener>

    <Kontener title="Jak do nas dołączyć">
            <p>Osoba o dobrym charakterze, bez względu na rasę, płeć, narodowość i status społeczny, która jest lojalna wobec swojego kraju i wierzy w Najwyższą Istotę, jest uprawniona do przystąpienia do zakonu. Odd Fellowship ściśle zabrania jakiejkolwiek ingerencji w przekonania religijne lub poglądy polityczne. </p>
            <p>Osoby zainteresowane po prostu mogą skontaktować się z Lożą, spotkać się z obecnym członkiem lub przyjść na imprezę sponsorowaną przez Odd Fellow i poinformować nas o swoim zamiarze. Jak tylko dowiemy się o twoim zamiarze, zasugerujemy odpowiednie kroki:

            <ul className="list-decimal ml-8 mb-2">
            <li>Dowiedz się o nas, jak najwięcej. Informacje są w każdej bibliotece i na całym Internecie. Albo znajdź nas na Facebooku lub Instagramie. Zapytaj ludzi, których znasz, czy znają kogoś, kto jest w Odd Fellows lub Rebekah. Sprawdź loże na tej stronie, czy któraś nie mieści się w tej samej miejscowości, co ty i wyślij wiadomość, przedstawiając się i wyrażając zainteresowanie przystąpieniem do zakonu.</li>
            <li>Znajdź lokalną Lożę, przyjdź do nas w dzień spotkania około 30 minut przed planowanym czasem i przedstaw się. Większość Loży chętnie szuka nowych członków i będzie zadowolona, że cię spotka. Uczestnicz w kolacjach i imprezach Loży, aby mieć okazję poznać członków i na własne oczy zobaczyć niektóre z programów dobroczynnych, w których bierzemy udział.</li> 
            <li>Odwiedź wybraną Lożę Odd Fellows</li>
            <li>Miej na uwadze, że rozważasz przystąpienie do światowego zakonu braterskiego.</li>
            <li>Rozważ czas, który możesz zaoferować. Oddfellowcy są nimi 24 godziny na dobę, siedem dni w tygodniu i 365 dni w roku. </li>
            <li>Jeśli po rozważeniu powyższego chcesz kontynuować, będziesz potrzebować wprowadzającego. Jeśli nie znasz osobiście nikogo w Loży i chcesz do niej przystąpić i jasno określisz swoje zamiary, zostanie ci przydzielony opiekun.</li>
            <li>To będzie Twój oficjalny początek. Będziesz uczony tego, czego się oczekuje i zostaniesz poproszony o udział w różnych imprezach i działaniach Loży.</li>
            <li>Kiedy twój wprowadzający ma pewność, że zdobyłeś wiedzę, która pozwoli ci podjąć dobrze uzasadnioną decyzję o przystąpieniu do zakonu, poinformuje o tym Lożę, a członkowie zagłosują na ciebie na zwykłym posiedzeniu.</li>
            <li className="text-white">Złożenie wniosku nie gwarantuje przyjęcia do Loży. To Loża decyduje, czy przyjąć cię jako członka. Jeśli głosowanie będzie pozytywne, zostaniesz przyjęty na najbliższej Loży Inicjacyjnej.</li>
            </ul>

</p>
    </Kontener>

    <Kontener title="Co możesz zrobić dla Nas">
        <p>Szukamy osób, które posiadają różne cenne umiejętności, które pomogą nam być jak najlepszym Zakonem. Mimo że istniejemy już od 200 lat, składamy się prawie wyłącznie z mężczyzn i kobiet, którzy poświęcają swój czas i talenty, aby Niezależny Zakon Odd Fellows stale rozrastał się i zmieniał świat na lepsze.</p>
        <p>Jak ty lub ktoś kogo znasz może nam pomóc? Np:</p>
        <ul className="list-disc ml-8 mb-2">
        <li>Poprzez  budowanie i zarządzanie stronami internetowymi i mediami społecznościowymi dla loży i Wielkiej Loży</li>
        <li>Pracować jako pośrednik między IOOF a innymi organizacjami i fundacjami współpracującymi z naszą społecznością</li>
        <li>Wykorzystać swoje umiejętności organizacyjne i księgowe do pomocy w zarządzaniu danymi i finansami oraz różnymi aktywami</li>
        <li>Wykorzystaj swoje umiejętności w mówieniu i pisaniu, aby sprawić, że bycie członkiem loży będzie zabawnym i ubogacającym doświadczeniem społecznym</li>
        <li>Wykorzystaj swoje umiejętności w marketingu lub PR do promowania IOOF na skalę lokalną, krajową i światową</li>
        <li>Wykorzystaj swoje umiejętności liderskie, aby zaangażować, uwolnić i motywować innych członków</li>
        <li>Wykorzystaj swoje umiejętności pisania i zarządzania dotacjami</li>
        <li>Ustaw i osiągaj krótko- i długoterminowe cele dla loży</li>
        </ul>
    </Kontener>

    </div>
    </>
  );
};

export default Index;
