import Kontener from "../components/kontener";


const Historia = () => {

    

  return (
    <>
    
    <div className="m-4 mx-8 text-text">
      
      <Kontener title="Wielka Loża Europejska IOOF">
      Czytając dokumenty historyczne, szybko zauważysz, że od początku 1890 roku urzędnicy Wielkiej Loży w Europie dyskutowali o możliwości utworzenia szerszej lub bardziej zintegrowanej organizacji europejskiej. Celem było wówczas, tak jak jest dziś, utworzenie wspólnej platformy dla Odd Fellows w Europie.
      Podobnie jak pomysły Thomasa Wildey'a i naszych przodków w Ameryce, którzy najpierw założyli Grand Lodge of United States, później zmienioną na Sovereign Grand Lodge Independent Order of Odd Fellows.
      Jednak trzeba było poczekać jeszcze sto lat, zanim jakiekolwiek perspektywiczne plany zostaną wprowadzone w życie.
      Dając krótki przegląd historii Grand Lodge of Europe I.O.O.F., nie mona nie wspomnieć o współpracujących jednostkach i radach łącznikowych działających przed powstaniem GLE.
      </Kontener>


      <Kontener title="Europejskie spotkanie Wielkich Mistrzów">
        Z raportu napisanego przez duńskiego Wielkiego Mistrza Petra Beyera z roku 1903 można przeczytać następujące:
        "Na piątej Konferencji Europejskich Grand Sires w Kopenhadze w Danii w dniach 17-18 maja obecni byli: Grand Sire Laurentz ze Szwecji, Grand Sire Gerlach z Niemiec, Grand Sire Philips z Holandii i Grand Sire Petrus Beyer z Danii. Grand Sire Hengst ze Szwajcarii z ogólnym żalem przeprosił za swoją nieobecność".
        Z powyższego można zauważyć, że pomysł utworzenia europejskiej organizacji współpracującej jest prawie tak stary jak sam Zakon w Europie. Ponadto pomysł utworzenia Europejskiej Grand Lodge narodził się już podczas tych spotkań.
        Archiwum pokazuje regularne Konferencje Grand Sire od 1949 roku (Dania) do 1989 roku (Niemcy). Prawdopodobnie częste spotkania odbywały się już dużo wcześniej.
       </Kontener>
   
       <Kontener title="Spotkania nordyckich oraz środkowoeuropejskich Wielkich Mistrzów">
        Od dawna Wielcy Mistrzowie, a potem także niektórzy Wielcy Urzędnicy Wielkich Lóż, spotykają się corocznie. Takie spotkania są organizowane w celu rozwiązania wspólnych spraw i zajęcia się powszechnymi problemami w obu regionach.
       </Kontener>

        <Kontener title="Federacja europejskich niezależnych jurysdykcji Zakonu Odd Fellows">
        <p>W połowie lat 80. Wielcy Mistrzowie niezależnych europejskich jurysdykcji zaniepokoili się rozwojem członkostwa na kontynencie. Brak jedności w Europie i zróżnicowane profile doprowadziły w maju 1989 roku do powstania Federacji Niezależnych Europejskich Jurysdykcji Odd Fellow Order I.O.O.F. - jako instytucji współpracy. Historyczne wydarzenie miało miejsce w Berlinie, Niemczech.</p>
        <p>
          Celem Federacji Europejskiej była ochrona, promocja i rozpowszechnianie zasad Odd Fellow.
          Kierownictwo, Zarząd, składało się z Przewodniczącego, Wiceprzewodniczącego, Sekretarza i Skarbnika.
          Regularne Konferencje miały odbywać się co dwa lata. Każda jurysdykcja miała jeden głos.
          Można zauważyć, że powstanie Federacji znacznie poprawiło komunikację między jurysdykcjami.
          Niepodważalne jest, że częstotliwość tych spotkań oraz fakt, że wielu Urzędników Europejskiej Wielkiej Loży pełni swoje funkcje przez dłuższy czas, stanowiło ważny element na poziomie krajowym i międzynarodowym.
        </p>
        <p>
            Konstytucja została uzgodniona 29 maja 1989 r. w Berlinie, Niemczech. Obecni wówczas byli Wielcy Mistrzowie Danii, Finlandii, Niemiec, Islandii, Holandii/Belgii, Norwegii, Szwecji i Szwajcarii.
            Odnosząc się do Konstytucji i Regulaminu Wielkiej Loży Europejskiej, warto podkreślić następujące fragmenty z pierwszej zatwierdzonej Konstytucji:
        </p>
        <ul className="list-disc ml-8">
          <li>Celem Federacji będzie ochrona, promocja i szerzenie zasad Odd Fellow.</li>
          <li>Jurysdykcje indywidualne zgadzają się brać aktywny udział w pracy w ramach Federacji.</li>
          <li>Wielki Mistrz i inni wybieralni urzędnicy z SGL są mile widziani na regularnych sesjach Federacji.</li>
          <li>Federacja zgadza się pracować nad utworzeniem organizacji Odd Fellow na całym świecie!</li>
          <li>Podobieństwa co do regaliów, porozumienia co do zmian rytuałów i ewentualnych wspólnych działań humanitarnych.</li>
          <li>Większość działalności ma mieć miejsce w podkomisjach.</li>
          <li>Siostry mogły być członkami podkomisji, ale nie Zarządu.</li>
        </ul>
        <p>W tamtym czasie organizacja Wielkich Loż i Zgromadzeń Rebekah (żeński odłam IOOF) bardzo różniła się od odbecnej.
Konstytucja EF (Europejskiej Federacji) została zmieniona 6 maja 2000 i 29 listopada 2003 roku. Ten ostatni raz ze względu na reorganizację, która miała miejsce po zaprzestaniu działalności ERLA.
Regulamin EF został przyjęty w Lozannie, w Szwajcarii 18 maja 1990 r., a później zmieniony zgodnie z Konstytucją.</p>

        <p>Podczas nadzwyczajnej Konferencji Wielkich Mistrzów Europy, która odbyła się w Kopenhadze, Dania, 4 i 5 lutego 1995 roku, postanowiono sporządzić notatkę "wszystkich działań europejskich jurysdykcji", aby przedstawić ogólne zarysy przyszłej współpracy w Europie. Projekt doprowadził do warsztatów we Frankfurcie 15 maja 1995 roku. Niektóre dostosowania zostały dokonane w późniejszym czasie. To działanie było jednym z pierwszych kroków ku zjednoczeniu Zgromadzenia w Europie.</p>
        </Kontener>

        <Kontener title="Europejskie Stowarzyszenie Mistrzyń Rebek - European Rebekah Leaders' Association - ERLA">
        Od momentu, gdy Wielcy Mistrzowie utworzyli Europejską Federację, liderki Rebek, w październiku 1989 roku w Sztokholmie, uznały za stosowne utworzenie Europejskiego Związku Mistrzyń Rebek. Oficjalne utworzenie miało miejsce 1 czerwca 1991 r. w Reykjaviku, na Islandii. Konstytucja została przyjęta tego samego dnia, a później ratyfikowana przez Konferencję Wielkich Mistrzów w maju 1992 roku.
Pomimo różnic Związek Mistrzyń Rebek miał niemal taki sam cel i zawartość jak Europejska Federacja.
Przed utworzeniem ERLA liderki Rebek spotykały się regularnie przez okres 10 lat.
4 maja 2003 roku ERLA zwołała nadzwyczajną konferencję z następującym punktem na agendzie:
"Czy mistrzynie Rebek chcą brać udział w kierownictwie Zakonu Odd Fellow w Europie poprzez członkostwo w Europejskiej Federacji?"
Dziś wiemy wszyscy, jaki był wynik tego pytania.
Połączenie ERLA z Europejską Federacją nastąpiło w 2003 roku.
        </Kontener>

        <Kontener title="Federacja Niezależnych Europejskich Jurysdykcji Zakonu Odd Fellows">
          <p>Po działaniu przez 14 lat (EF) odpowiednio 13 lat (ERLA), Komitety Wykonawcze zrozumiały, że nadszedł czas połączenia zakonu męskiego i żeńskiego.
          To również w kontekście zmian, jakie zaszły w ostatnich latach w poszczególnych Zarządach Europejskich Jurysdykcji.
          Zgodnie z Regulaminem EF i ERLA, Przewodniczący, Hans Pedersen, Dania, w liście z dnia 9 listopada 2003 roku, zaprosił Komitety Wykonawcze EF i ERLA na spotkanie w Kopenhadze 29 listopada 2003 roku.
          Agenda miała tylko dwa punkty:</p>

          <ul className="list-decimal ml-8">
          <li>Wniosek o połączenie EF i ERLA, od 1 stycznia 2004 roku</li>
          <li>Utworzenie Zarządu złożonego z przedstawicieli obecnych Zarządów, aż do pierwszego zwykłego posiedzenia.
          Na wspólnym spotkaniu Przewodniczący Hans Pedersen przedstawił dobrze udokumentowaną informację o sytuacji w każdej jurysdykcji, na tamten dzień.
          Ponadto, w imieniu Zarządu Europejskiej Federacji, przedstawił projekt wspólnej Konstytucji i Regulaminu. Oba zostały przyjęte z drobnymi poprawkami.</li>
          </ul>

          <p>W kolejnych latach Europejska Federacja otrzymała wiele zapytań o członkostwo od prywatnych osób w Europie.
            Niestety, żadne z nich nie przyczyniło się do dalszego rozwoju. Zamiast tego Rada i podkomitety wspierały działalność w nowo 
            utworzonych jednostkach w Polsce, Hiszpanii, Czechach i Estonii.</p>

          <p>Nie ma wątpliwości, że Europejczycy często, poprzez Międzynarodowa Rada i Europejską Federację, podnosili
             potrzebę otrzymania autonomii i suwerenności w Europie ze strony Wielkiej Loży Suwerennej (z Ameryki).
            Brak przywództwa wyraźnie stał się widoczny, gdy loża w Austrii została założona bez wiedzy żadnej z Europejskich Wielkich Lóż.
            Z dużym zaskoczeniem dla Europejczyków, Wielka Loża Suwerenna ogłosiła, że założyła lożę - Paracelsus Loge - w Salzburgu w Austrii.
            Fakt, że niektórzy z członków byli byłymi  (wykluczonymi)  członkami w Niemczech, oraz że zebrania loży często odbywały się w Niemczech,
            utrudnił relacje transoceaniczne. Loża miała być podporządkowana Wielkiej Loży Suwerennej, a nie w obszarze Europejskiej Federacji.</p>

          <p>Po kilku latach, kilku negocjacjach i ciężkich dyskusjach, Akt Erekcyjny został oddany Wielkiej Loży Suwerennej.

Komitet Spraw Braterskich i Siostrzanych był bardzo ważnym komitetem w EF. Szczególnie w związku z nadchodzącą równością płci, ponieważ jurysdykcje 
przygotowywały się do większej równości w Radach/Wielkich Lożach.

Ponadto, ten komitet został poproszony o opracowanie nowego Niepisanego Prawa, opartego na starych historycznych przekazach.</p>
          <p>Międzynarodowa Rada nie jest w zasadzie odniesionya w tym przypadku. Należy jednak zauważyć, że przedstawiciele 
            EF w Międzynarodowej Radzie, w okresie od 2000 do 2006 roku, złożyli kilka krytycznych pytań. 
            Wśród nich był cel powyższego Rady, jego relacje i podporządkowanie Wielkiej Loży Suwerennej. 
            Między wierszami można było odczytać intencję utworzenia IC jako Międzynarodowej Radzie i
            uczynienie Zakonu ogólnoświatowym z mocnym przywództwem.</p>
          <p>Wielki Mistrz Suwerenny nie zaakceptował tej idei, podobnie jak przedstawiciele SGL do IC. Zdecydowanie stwierdzili, 
            że istnieje tylko jedno przywództwo w Niezależnym Zakonie Odd Fellow. To jest Wielka Loża Suwerenna; będąca suwerenną.
            </p>
            <p>
          W związku z sesją Wielkiej Loży Suwerennej w 2005 roku, Europejczycy osiągnęli ważny postęp, ponieważ Komitet ds. Stanu Zakonu 
          zgodził się przygotować raport na rzecz Niezależnej Wielkiej Loży Europejskiej. Co więcej, w związku z posiedzeniem 
          Międzynarodowej Rady w Willowbrook w USA w 2005 roku, Brat John Mrizek zaproponował: 
          „aby Międzynarodowa Rada polecił Federacji Niezależnych Europejskich Jurysdykcji rozważenie reformowania Wielkiej Loży Europy: 
          dalej, aby prośba o to została przedstawiona  Radzie do rozpatrzenia i przedłożenia Wielkiej Loży Suwerennej”.</p>
          <p>Obecny był Wielki Mistrz Suwerenny C. LaVaughn Lawson. Z dużym zaskoczeniem dla Europejczyków, nie miał zastrzeżeń. 
            Podjęto pierwszy krok w kierunku zainicjowania Wielkiej Loży dla Europy. Brat John Mrizek, będąc Grand Representative do 
            Wielkiej Loży Suwerennej przez wiele lat, był osobą, której głosujący przedstawiciele SGL słuchali. 
            Przejście przez Międzynarodową Radę miało tę zaletę, że prośba zostałaby wydana zgodnie z protokołem SGL/amerykańskim.

          Będąc jednym z przedstawicieli do Międzynarodowej Rady w tym czasie, Wielki Mistrz Harald Thoen przedstawił 
          następujący tekst na Konferencji Europejskiej Federacji w Kopenhadze w Danii w 2006 roku:</p>
          <p>"My - Federacja Niezależnych Europejskich Jurysdykcji Zakonu I.O.O.F. - wnosimy, na podstawie Raportu nr 142, 
            Komisji ds. Stanu Zakonu, sesji SGL 2005, o przyznanie glejtu dla Niezależnej Wielkiej Loży Europy, 
            podpisanej przez Wielkich Mistrzów wszystkich europejskich jurysdykcji, 
            która ma zostać przekazana Międzynarodowej Radzie podczas sesji w Kopenhadze 9-10 czerwca, 
            z zamiarem przedstawienia prośby do Wielkiej Loży Suwerennej".
          </p>
          <p>Powyższe zostało jednomyślnie zatwierdzone przez ośmiu europejskich Wielkich Mistrzów i przesłane do Międzynarodowej Radzie.</p>
          
        </Kontener>

        <Kontener title="Międzynarodowa Rada/Międzynarodowa Rada Doradcza Odd Fellow (INTERNATIONAL COUNCIL/INTERNATIONAL ADVISORY COUNCIL OF ODD FELLOWS)">
        <p>Instytucja Międzynarodowej Rady miała miejsce w Kopenhadze 2 maja 1949 r. Pierwszy jej komitet zarządzający składał się z: 
          Grand Sire Knud Nielsen, Denmark, prezes, Grand Sire Ernst Höijer, Sweden, wiceprezes i Sovereign Grand Secretary Edward G. Ludvigsen, USA,
           sekretarz. Historia Międzynarodowej Rady nie jest tu powtarzana. Jednak nazwa ta ilustruje misję. Już parę lat później, 
           na spotkaniu w Baltimore w 1951 r., pojawił się wniosek o przyznanie członkostwa Rebekom w Radzie.</p>
          <p>Zgłoszenie zostało jednak odrzucone, lecz od 1999 roku przyznano im członkostwo.
            Przez długi czas spotkania w Międzynarodowym Radzie odbywały się co 3 lata, w których uczestniczyło 30-40 oficerów Grand Lodge. Dziś nazwa została zmieniona na Międzynarodowe Rady Doradcze, składające się z 13 członków, w tym dwóch przedstawicieli Manchester Unity.
            Rada Doradcza spotyka się w związku z sesją SGL.</p>

        </Kontener>
        

    </div>
    </>
  );
};

export default Historia;
